import { combineReducers } from "redux";
import * as ACTION_TYPES from "../actions/actionTypes";

const initialState = {
  isRTL: Boolean(JSON.parse(localStorage.getItem("isRTL"))),
};

const basicTheme = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_DIRECTION:
      return {
        ...state,
        isRTL: action.data,
      };

    default:
      return state;
  }
};

export default combineReducers({
  basicTheme,
});
