import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import users from "./groups/users";
import theme_reducer from "./theme";
import fileReducers from "./groups/file";
import staticData from "./groups/staticData";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    users,
    theme_reducer,
    fileReducers,
    staticData,
  });

export default rootReducer;
