import { Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createTheme,
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { lazy, memo, Suspense, useEffect, useRef, useState } from "react";
import { useJwt } from "react-jwt";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import useStyles from "../styles/pages/spinner";

const Footer = lazy(() => import("../pages/footer/Footer"));
const SignIn = lazy(() => import("../pages/signIn/SignIn"));
const Registration = lazy(() => import("../pages/registration/Registration"));
const Thanks = lazy(() => import("../pages/thanks/Thanks"));
const Error = lazy(() => import("../pages/error/Error"));
const UserProfileButton = lazy(() =>
  import("../components/userProfileButton/UserProfileButton")
);
const ChangePassword = lazy(() =>
  import("../pages/reset-password/ResetPassword")
);
const ResetPassword = lazy(() =>
  import("../pages/forget-password/ResetPassword")
);
const ForgetPassword = lazy(() =>
  import("../pages/forget-password/ForgetPassword")
);
const attendeeProfile = localStorage.getItem("attendeeProfile");
const attendeeToken = localStorage.getItem("attendeeToken");

function Routes() {
  const [toggleSpinner, setToggleSpinner] = useState(false);
  const reducers = useSelector((state) => state);
  const { isExpired } = useJwt(attendeeToken);
  const [theme, setTheme] = useState(createTheme());
  const {
    basicTheme: { isRTL },
  } = useSelector((state) => state.theme_reducer);
  const classes = useStyles();
  const { pathname: currentUrl } = useLocation();

  useEffect(() => {
    if (
      reducers?.users?.logIn ||
      reducers?.users?.getById ||
      reducers?.users?.update ||
      reducers?.users?.resetPasswordAction ||
      reducers?.fileReducers?.downloadFile ||
      reducers?.fileReducers?.downloadLogo ||
      reducers?.staticData?.getStaticData
    )
      setToggleSpinner(true);
    else setToggleSpinner(false);
  }, [reducers]);

  useEffect(() => {
    if (attendeeToken && isExpired) window.location.href = "/adsd/signin";
    else if (currentUrl === "/" && !attendeeToken)
      window.location.href = "/adsd/signin";
  }, [currentUrl]);

  useEffect(() => {
    let newTheme;
    if (isRTL) {
      newTheme = { ...theme, side: true };
    } else newTheme = { ...theme, side: false };
    setTheme(newTheme);
  }, [isRTL]);

  // Scroll to top if path changes

  const componentRef = useRef();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Paper
          ref={componentRef}
          display="flex"
          flex-direction="column"
          width="100%"
          style={{
            position: "relative",
            minHeight: "100vh",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Suspense fallback={<h1></h1>}>
            {(currentUrl === "/attendee/registration" ||
              currentUrl === "/thankyou" ||
              currentUrl === "/change-password") && <UserProfileButton />}
            <Switch>
              <Redirect exact from={"/"} to={"/signin"} />
              <Redirect exact from={"/en/home"} to={"/signin"} />
              <Route exact path={"/signin"} render={() => <SignIn />} />{" "}
              <Route
                exact
                path={"/attendee/registration"}
                render={() => <Registration />}
              />
              <Route exact path={"/thankyou"} render={() => <Thanks />} />
              <Route
                exact
                path={"/change-password"}
                render={() => <ChangePassword />}
              />
              <Route
                exact
                path={"/reset-password/:email/:code"}
                render={() => <ResetPassword />}
              />
              <Route
                exact
                path={"/forget-password"}
                render={() => <ForgetPassword />}
              />
              <Route component={() => <Error />} />
            </Switch>
            {toggleSpinner ? (
              <div className={classes.spinner}>
                <PropagateLoader
                  color={"#00aedb"}
                  loading={true}
                  size={15}
                  speedMultiplier={1}
                />
              </div>
            ) : null}
            <Footer />
          </Suspense>
        </Paper>
      </ThemeProvider>
    </StylesProvider>
  );
}
export default memo(Routes);
