import { createAction } from "../creators";

export default {
  ...createAction("UPLOAD_FILE", "payload", "createPayload"),
  ...createAction("FILE_UPLOADED", "response"),
  ...createAction("CREATE_FILE", "payload"),
  ...createAction("FILE_CREATED", "response"),
  ...createAction("DOWNLOAD_FILE", "payload"),
  ...createAction("FILE_DOWNLOADED", "response"),
  ...createAction("FILE_NOT_CREATED", "response"),
  ...createAction("DOWNLOAD_LOGO"),
  ...createAction("LOGO_DOWNLOADED", "response"),
};
