import { postData } from "./api";
let token = localStorage.getItem("attendeeToken");

export const logIn = async (data) =>
  await postData("/api/attendees/login", data);

export const update = async (data, id) =>
  await postData(`/api/attendees/${id}/update`, data, { token });

export const deleteItem = async (sort, id) =>
  await postData(`/api/${sort}/${id}/delete`, null, { token });

export const forgotPassword = async (data) =>
  await postData("/api/attendees/password/forget", data);

export const resetPassword = async (data) =>
  await postData("/api/attendees/password/reset", data);
