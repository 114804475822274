import actions from "../../actions";
import { combineReducers } from "redux";

const {
  LOG_IN_ACTION,
  LOG_IN_COMPLETE,
  UPDATE,
  UPDATED,
  CLEAR,
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_EMAIL_RETURNED,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_RETURNED,
} = actions;

export const logIn = (state = false, action) => {
  switch (action.type) {
    case LOG_IN_ACTION:
      return true;
    case LOG_IN_COMPLETE:
      return false;
    default:
      return state;
  }
};
export const logInComplete = (state = false, action) => {
  switch (action.type) {
    case LOG_IN_COMPLETE:
      return action.data;
    default:
      return state;
  }
};

export const update = (state = false, action) => {
  switch (action.type) {
    case UPDATE:
      return true;
    case UPDATED:
      return false;
    default:
      return state;
  }
};
export const updated = (state = false, action) => {
  switch (action.type) {
    case UPDATED:
      return action.response;
    case CLEAR:
      return false;
    default:
      return state;
  }
};

export const forgot = (state = false, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_ACTION:
      return true;
    case FORGOT_PASSWORD_EMAIL_RETURNED:
      return false;
    default:
      return state;
  }
};
export const forgotPasswordComplete = (state = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_EMAIL_RETURNED:
      return action.data;
    case CLEAR:
      return false;
    default:
      return state;
  }
};

export const reset = (state = false, action) => {
  switch (action.type) {
    case RESET_PASSWORD_ACTION:
      return true;
    case RESET_PASSWORD_RETURNED:
      return false;
    default:
      return state;
  }
};
export const resetPasswordComplete = (state = null, action) => {
  switch (action.type) {
    case RESET_PASSWORD_RETURNED:
      return action.data;
    case CLEAR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  logIn,
  logInComplete,
  update,
  updated,
  forgot,
  forgotPasswordComplete,
  reset,
  resetPasswordComplete,
});
