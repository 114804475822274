import actions from "../../actions";
import { combineReducers } from "redux";

const { GET_STATIC_DATA, STATIC_DATA_RETURNED } = actions;

export const getStaticData = (state = false, action) => {
  switch (action.type) {
    case GET_STATIC_DATA:
      return true;
    case STATIC_DATA_RETURNED:
      return false;
    default:
      return state;
  }
};
export const staticDataReturned = (state = [], action) => {
  switch (action.type) {
    case STATIC_DATA_RETURNED:
      return {
        ...state,
        [action?.key]: action.data,
      };
    default:
      return state;
  }
};

export default combineReducers({
  getStaticData,
  staticDataReturned,
});
