import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../../actions";
import {
  logIn,
  update,
  deleteItem,
  forgotPassword,
  resetPassword,
} from "../../network/users";
import { createFile, uploadFile } from "../../network/file";

const {
  LOG_IN_ACTION,
  logInComplete,
  UPDATE,
  updated,
  fileUploaded,
  FORGOT_PASSWORD_ACTION,
  forgotPasswordEmailReturned,
  RESET_PASSWORD_ACTION,
  resetPasswordReturned,
} = actions;

function* performSignIn(action) {
  try {
    let data = action.payload;
    const result = yield call(logIn, data);
    if (result) {
      localStorage?.setItem("attendeeToken", result.data?.token);
      localStorage?.setItem(
        "attendeeProfile",
        JSON.stringify(result.data?.attendee)
      );
      yield put(logInComplete({ data: result.data }));
    } else yield put(logInComplete({ data: [] }));
  } catch {
    yield put(logInComplete({ data: [] }));
    return;
  }
}

export function* watchLogIn() {
  yield takeLatest(LOG_IN_ACTION, performSignIn);
}

function* performUpdate(action) {
  try {
    let data = action.payload;
    let id = action.id;
    const passDoc = data.passDoc || [];
    const currentPassDoc = data.currentPassDoc || [];
    const passDocIds = [];
    const passPhoto = data.passPhoto || [];
    const currentPassPhoto = data.currentPassPhoto || [];
    const passPhotoIds = [];
    const covid = data.covid || [];
    const currentCovid = data.currentCovid || [];
    const covidIds = [];
    let updatedFields = action?.updatedFields || [];

    if (passDoc && passDoc?.length) {
      var formData = new FormData();
      passDoc.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error;
        if (responseData?.data?.code === 111)
          error = "Passport /Emirates ID File Extension is not Allowed";
        else if (responseData?.data?.code === 112)
          error = "Passport /Emirates ID File Size is Too Large";
        else
          error =
            responseData?.data?.message + " for Passport /Emirates ID Field";
        yield put(fileUploaded({ response: error }));
      }
      let payload = [];
      responseData.data.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          publishMode: 3,
          alt: { en: "Passport" || null },
          title: { en: "Passport" || null },
          link: "passDoc",
          ...file,
        });
      });

      let filesPayload = yield call(createFile, payload);
      filesPayload.data.files.map((files) => passDocIds.push(files.id));
      updatedFields = [...updatedFields, "passDocIds"];
    }
    if (currentPassDoc && currentPassDoc?.id && passDoc?.length) {
      yield call(deleteItem, "file", currentPassDoc?.id);
    }

    if (covid && covid?.length) {
      var formData = new FormData();
      covid.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);

      if (!responseData?.data?.success) {
        let error;
        if (responseData?.data?.code === 111)
          error = "COVID Vaccination Certificate Extension is not Allowed";
        else if (responseData?.data?.code === 112)
          error = "COVID Vaccination Certificate Size is Too Large";
        else
          error =
            responseData?.data?.message +
            " for COVID Vaccination Certificate Field";
        yield put(fileUploaded({ response: error }));
      }
      let payload = [];
      responseData.data.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          publishMode: 3,
          alt: { en: "COVID Vaccination Certificate " || null },
          title: { en: "COVID Vaccination Certificate " || null },
          link: "covid",
          ...file,
        });
      });

      let filesPayload = yield call(createFile, payload);
      filesPayload.data.files.map((files) => covidIds.push(files.id));
      updatedFields = [...updatedFields, "covidIds"];
    }
    if (currentCovid && currentCovid?.id && covid?.length) {
      yield call(deleteItem, "file", currentCovid?.id);
    }
    if (passPhoto && passPhoto?.length) {
      var formData = new FormData();
      passPhoto.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);

      if (!responseData?.data?.success) {
        let error;
        if (responseData?.data?.code === 111)
          error = "Personal Image File Extension is not Allowed";
        else if (responseData?.data?.code === 112)
          error = "Personal Image File Size is Too Large";
        else error = responseData?.data?.message + " for Personal Image Field";
        yield put(fileUploaded({ response: error }));
      }
      let payload = [];
      responseData.data.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          publishMode: 3,
          alt: { en: "Personal Image" || null },
          title: { en: "Personal Image" || null },
          link: "passPhoto",
          ...file,
        });
      });

      let filesPayload = yield call(createFile, payload);
      filesPayload.data.files.map((files) => passPhotoIds.push(files.id));
      updatedFields = [...updatedFields, "photoId"];
    }
    if (currentPassPhoto && currentPassPhoto?.id && passPhoto?.length) {
      yield call(deleteItem, "file", currentPassPhoto?.id);
    }
    delete data["currentPassDoc"];
    delete data["currentPassPhoto"];
    delete data["passDoc"];
    delete data["covid"];
    delete data["passPhoto"];
    if (passDocIds?.length) data = { ...data, passportDocId: passDocIds[0] };
    if (passPhotoIds?.length) data = { ...data, photoId: passPhotoIds[0] };

    if (covidIds?.length) data = { ...data, covidId: covidIds[0] };
    let creatingData = { ...data, updatedFields: updatedFields };

    let result = yield call(update, creatingData, id);

    if (result?.data?.success) {
      yield put(updated({ response: result.data }));
    } else {
      yield put(updated({ response: result.data }));
    }
  } catch (e) {
    yield put(updated({ response: "" }));
    return;
  }
}

export function* watchUpdate() {
  yield takeLatest(UPDATE, performUpdate);
}

function* performForgotPassword(action) {
  try {
    let data = action.payload;
    const result = yield call(forgotPassword, data);
    if (result) yield put(forgotPasswordEmailReturned({ data: result.data }));
    else yield put(forgotPasswordEmailReturned({ data: result.data }));
  } catch {
    yield put(forgotPasswordEmailReturned({ data: [] }));
    return;
  }
}

export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_ACTION, performForgotPassword);
}

function* performResetPassword(action) {
  try {
    let data = action.payload;
    const result = yield call(resetPassword, data);
    if (result) yield put(resetPasswordReturned({ data: result.data }));
    else yield put(resetPasswordReturned({ data: {} }));
  } catch {
    yield put(resetPasswordReturned({ data: [] }));
    return;
  }
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_ACTION, performResetPassword);
}
