import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../../actions";
import { getStaticData } from "../../network/staticData";

const { GET_STATIC_DATA, staticDataReturned } = actions;

function* performGetStaticData(action) {
  try {
    let key = action?.key;
    let sort = action?.sort;
    let token = action?.token;
    const result = yield call(getStaticData, sort, key, token);
    if (result && result.res?.length) {
      yield put(staticDataReturned({ data: result.res, sort, key }));
    } else {
      yield put(staticDataReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetStaticData() {
  yield takeEvery(GET_STATIC_DATA, performGetStaticData);
}
