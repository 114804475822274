import { createAction } from "../creators";

export default {
  ...createAction("LOG_IN_ACTION", "payload"),
  ...createAction("LOG_IN_COMPLETE", "data"),
  ...createAction("UPDATE", "id", "payload", "updatedFields"),
  ...createAction("UPDATED", "sort", "response"),
  ...createAction("CLEAR", "data"),
  ...createAction("FORGOT_PASSWORD_ACTION", "payload"),
  ...createAction("FORGOT_PASSWORD_EMAIL_RETURNED", "data"),
  ...createAction("RESET_PASSWORD_ACTION", "payload"),
  ...createAction("RESET_PASSWORD_RETURNED", "data"),
};
