import { fork } from "redux-saga/effects";
import * as users from "./groups/users";
import * as fileSagas from "./groups/file";
import * as staticData from "./groups/staticData";

const sagas = Object.values({
  ...users,
  ...fileSagas,
  ...staticData,
});

export default function* rootSaga(getState) {
  for (let index = 0; index < sagas.length; index++) {
    yield fork(sagas[index]);
  }
}
