import users from "./groups/users";
import theme from "./groups/theme";
import file from "./groups/files";
import staticData from "./groups/staticData";

export default {
  ...users,
  ...theme,
  ...file,
  ...staticData,
};
