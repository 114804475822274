import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { history, store } from "./redux/store";
import Index from "./routes/Index";
import "./styles/App.css";
function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Index />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
