import {
  uploadFile,
  downloadFile,
  createFile,
  downloadLogo,
} from "../../network/file";
import { call, put, takeLatest, all } from "redux-saga/effects";
import actions from "../../actions";
const {
  UPLOAD_FILE,
  fileCreated,
  DOWNLOAD_FILE,
  fileDownloaded,
  fileNotCreated,
  DOWNLOAD_LOGO,
  logoDownloaded,
} = actions;

function* performUploadFile(action) {
  try {
    var formData = new FormData();
    const data = action.payload;
    data.map((file) => formData.append("files", file));
    const result = yield call(uploadFile, formData);
    if (result.data.success) {
      const filesDetails = [];
      result.data.files.map((file) =>
        filesDetails.push({
          uuid: file.filename,
          publishMode: 1,
          acl: 1,
          extension: file.originalname.split(".").pop(),
          title: {
            ar: file.originalname ? file.originalname : file?.name,
            en: file.originalname ? file.originalname : file?.name,
          },
          ...file,
        })
      );

      const createdFile = yield call(createFile, filesDetails);
      const response = createdFile.data;

      if (createdFile) {
        yield put(fileCreated({ response }));
        yield put(fileNotCreated({ response: false }));
      } else yield put(fileCreated({ response: [] }));
    } else {
      const err = result.data.message;
      yield put(fileNotCreated({ response: err }));
    }
  } catch (error) {
    console.error(error, "result");
  }
}
export function* watchUploadFile() {
  yield takeLatest(UPLOAD_FILE, performUploadFile);
}

function* performDownloadFile(action) {
  try {
    if (action.payload.files) {
      let files = action.payload.files;
      let fullDataFiles = yield all(
        files &&
          files.map(async (file) => ({
            ...file,
            binaryData: await downloadFile(file.uuid),
          }))
      );
      yield put(fileDownloaded({ response: fullDataFiles }));
    } else if (action.payload.item) {
      let data = action.payload.item.uuid;
      const result = yield call(downloadFile, data);
      yield put(fileDownloaded({ response: result }));
    }
  } catch (error) {
    yield put(fileDownloaded({ response: [] }));
  }
}
export function* watchDownloadFile() {
  yield takeLatest(DOWNLOAD_FILE, performDownloadFile);
}

function* performDownloadLogo() {
  try {
    const result = yield call(downloadLogo);
    yield put(logoDownloaded({ response: result }));
  } catch (error) {
    yield put(logoDownloaded({ response: [] }));
  }
}
export function* watchDownloadLogo() {
  yield takeLatest(DOWNLOAD_LOGO, performDownloadLogo);
}
