import axios from "axios";
import { fetchBinaryData, postData } from "./api";
let token = localStorage.getItem("attendeeToken");

export const uploadFile = async (data) => {
  try {
    return await axios.post(`/api/file/upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    });
  } catch (error) {
    return false;
  }
};

export const createFile = async (data) =>
  await postData(`/api/file`, data, { token });

export const downloadFile = async (uuid) =>
  await fetchBinaryData(`/api/file/download/${uuid}`, null, null);

export const downloadLogo = async () =>
  await fetchBinaryData("/api/file/logo", null, { token });
