import actions from "../../actions";
import { combineReducers } from "redux";

const {
  UPLOAD_FILE,
  FILE_UPLOADED,
  UPDATE_FILE,
  FILE_UPDATED,
  CREATE_FILE,
  FILE_CREATED,
  DOWNLOAD_FILE,
  FILE_DOWNLOADED,
  FILE_NOT_CREATED,
  DOWNLOAD_LOGO,
  LOGO_DOWNLOADED,
  CLEAR,
} = actions;
export const uploadFile = (state = false, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return true;
    case CLEAR:
      return false;
    case FILE_UPLOADED:
      return false;

    default:
      return state;
  }
};
export const fileUploaded = (state = false, action) => {
  switch (action.type) {
    case FILE_UPLOADED:
      return action.response;
    case CLEAR:
      return false;
    default:
      return state;
  }
};
export const fileCreated = (state = null, action) => {
  switch (action.type) {
    case FILE_CREATED:
      return action.response;
    case FILE_NOT_CREATED:
      return false;
    default:
      return state;
  }
};
export const fileNotCreated = (state = false, action) => {
  switch (action.type) {
    case FILE_NOT_CREATED:
      return action.response;

    default:
      return state;
  }
};
export const downloadFile = (state = false, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE:
      return true;
    case FILE_DOWNLOADED:
      return false;

    default:
      return state;
  }
};
export const downloadedFiles = (state = [], action) => {
  switch (action.type) {
    case FILE_DOWNLOADED:
      return action.response;

    default:
      return state;
  }
};
export const downloadLogo = (state = false, action) => {
  switch (action.type) {
    case DOWNLOAD_LOGO:
      return true;
    case LOGO_DOWNLOADED:
      return false;

    default:
      return state;
  }
};
export const logoDownloaded = (state = [], action) => {
  switch (action.type) {
    case LOGO_DOWNLOADED:
      return action.response;
    default:
      return state;
  }
};

export default combineReducers({
  uploadFile,
  fileUploaded,
  fileCreated,
  fileNotCreated,
  downloadFile,
  downloadedFiles,
  downloadLogo,
  logoDownloaded,
});
